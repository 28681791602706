import type { FunctionComponent, ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export type Props = {
  children?: ReactNode;
  replace?: boolean;
  selector: string;
};

export const Portal: FunctionComponent<Props> = ({ children, replace = false, selector }) => {
  const [replaced, setReplaced] = useState(false);
  const reference = useRef<Element | null>(null);

  useEffect(() => {
    reference.current = document.querySelector(selector);

    if (reference.current && replace && !replaced) {
      reference.current.innerHTML = "";
      setReplaced(true);
    }
  }, [replace, replaced, selector]);

  return reference.current ? createPortal(children, reference.current) : null;
};

Portal.displayName = "Portal";
