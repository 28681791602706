/**
 * @generated SignedSource<<5bde2b7afac1c59dd5598429edd2c238>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTrackCurrentItemBase$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"atomsCurrentArticleAtomBase" | "atomsCurrentNotificationSectionAtomBase" | "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase" | "atomsCurrentTakeoverAdSectionsAtomBase">;
  readonly " $fragmentType": "hooksTrackCurrentItemBase";
};
export type hooksTrackCurrentItemBase$key = {
  readonly " $data"?: hooksTrackCurrentItemBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTrackCurrentItemBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTrackCurrentItemBase"
};

(node as any).hash = "a838df1567ccc1cc3ee365a3473bdfe3";

export default node;
