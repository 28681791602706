import { getGAClientIdFromGTM } from "@product/scmp-sdk";

import { config } from "scmp-app/data";

export const getGoogleAnalyticsClientIdSync = () => {
  if (typeof ga === "undefined") return;
  const instance = ga?.getAll?.()?.[0];
  if (!instance) return;
  return instance.get("clientId") as string;
};

export const getGoogleAnalyticsClientId = () =>
  getGoogleAnalyticsClientIdSync() ?? getGAClientIdFromGTM(config.googleAnalytics.measurementId);
